.request-demo{
    background-color: white;
    width: 175px;
    height: 50px;
    color: black;
    border: 0;
    font-size: 20px;
    font-weight: 700;
    align-self: center;
    cursor: pointer;
}

.request-demo:hover{
    background-color: lightgray;
}

.our-machines{
    width: 175px;
    height: 50px;
    background-color: #5271ff;
    color: white;
    border: 0;
    font-size: 20px;
    font-weight: 700;
    align-self: center;
    margin-right: 50px;
    cursor: pointer;
}

.our-machines:hover {
    background-color: #435ed4;
}

.video-content{
    max-width:1280px; 
    max-height:720px;
    width: 100%;
}


.content-container{
    background-image: linear-gradient(to bottom, #5271ff, #5271ff30 40%, rgba(0, 0,  0, 0) 70%);
    padding-top: 70px;
}

.content-container > h1{
    text-align: center;
    font-size: 48px;
}

.heading2-1{
    text-align: center;
    margin-top: 40px;
    font-size: 48px;
}

.heading2-2{
    text-align: center;
    font-size: 48px;
}

.selling-points{
    display: flex;
    flex-direction: column;
}

.points-container{
    display: flex;
    align-items: center;
}

.points{
    background-color: #5271ff;
    margin-top: 200px;
    margin-bottom: 200px;
    align-self: flex-end;

    padding: 10px;
}

.points-title{
    color: white;
    padding-left: 50px;
    font-size: 36px;
    margin-bottom: 18px;
}

.points-desc{
    padding-left: 50px;
    padding-right: 50px;
    font-size: 24px;
    margin-top: 0px;
}

.img-container{
    margin-left: 100px;
    margin-right: 100px;
}

.points-img{
    width: 500px;
    height: 600px;
}

.contact-field{
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #5271ff;
    padding-bottom: 20px;
}

.contact-field > h2{
    color: white;
    text-align: center;
    font-size: 46px;
    margin-top: 10px;
    max-width: 700px;
}

.contact-field-button{
    color: #5271ff;
    background-color: white;
    border-style: none;
    width: 240px;
    height: 60px;
    border: 0;
    font-size: 20px;
    font-weight: 700;
    align-self: center;
    cursor: pointer;
}

.contact-field-button:hover{
    background-color: whitesmoke;
}

.home-banner {
  display: flex;
  padding-left: 40px;
  flex-direction: column;
  justify-content: center;
  height: 665px;
  background-image: linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(252,176,69,0) 40%), 
    url(../assets/bb2301-artwork-03.jpg);
  background-size: cover;
  background-position: center;
}

.home-view-machines {
    width: 175px;
    height: 50px;
    background-color: white;
    color: #5271ff;
    border-width: 2px;
    border-style: solid;
    border-color: #5271ff;
    border-radius: 5px;
    font-size: 20px;
    font-weight: 700;
    margin-bottom: 30px; 
    margin-top: 30px;
    cursor: pointer;
}

.home-view-machines:hover {
    background-color: #5271ff;
    color: white;
    border-style: none;

}

.home-product-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.partner-logo{
    max-height: 100px;
}  

.partner-container{
    display: flex;
    justify-content: space-evenly;
    padding: 35px 0px 35px 0px;
}

.partner-title{
    text-align: center;
    padding-top: 20px;
    font-size: 30px;
}

.video-container{

    display: flex;
    align-items: center;
    width: 100%;
    justify-content: center;
}

@media screen and (max-width: 1400px) {

}

@media screen and (max-width: 1100px) {

    .content-container{
        padding-top: 50px;
    }

    .points-container{
        flex-wrap: wrap;
    }

    .img-container{
        margin-left: auto;
        margin-right: auto;
    }
    
    .points{
        margin-top: 10px;
        margin-bottom: 50px;
        margin-left: 20px;
        margin-right: 20px;
        width: 100%;
        border-radius:5px;
    }

    .points-container.left{
        flex-wrap: wrap-reverse;
    }
    
    .points-container.right{
        flex-wrap: wrap;
    }

    .points-container h2{
        padding-left: 0px;
        text-align: center;
    }

    .points-container p{
        padding-left: 15px;
        padding-right: 15px;
    }

    .partner-logo{
        height: 70px;
    }
}

@media screen and (max-width: 900px) {


    .points-img{
        width: 350px;
        height: 420px;
    }

    .partner-logo{
        height: 60px;
    }
}

@media screen and (max-width: 600px) {



    .content-container{
        padding-top: 30px;
    }

    .partner-logo{
        height: 40px;
    }

    .content-container{
        background-image: linear-gradient(to bottom, #5271ff, #5271ff30 25%, rgba(0, 0,  0, 0) 75%);
        padding-top: 70px;
    }
}

@media screen and (max-width: 500px) {
    .our-machines{
        margin-bottom: 30px;
    }

}