.f-container{
  color: #5271ff;
  background-color: white;
  padding-bottom: 20px;
  padding-left: 50px;
  padding-right: 50px;
  padding-top: 20px;
}

.footer{
  display: flex;
  margin-bottom: 80px;
  flex-wrap: wrap;
}

.footer-section-l{
  flex: 1;
}

.footer-section-r{
  display: flex;
  align-self: flex-end;
  flex: 1;
  justify-content: space-evenly;
}

.sections > h1{
  margin-top: 25px;
  font-size: 24px;
  text-align: left;
}

small{
  display: block;
  text-align: center;
}

.section-items{
  list-style: none;
  padding-left: 0px;
  text-align: left;
  margin-top: 0px;
}

.section-items > li{
  margin-bottom: 25px;
  font-size: 20px;
  color: #5271ff;
}

.section-items li a{
  color: #5271ff;
}

.company-desc{
  max-width: 650px;
  font-size: 20px;
  margin-top: 10px;
  margin-bottom: 30px;
}

.footer-logo{
  height: 80;
}

hr{
  border-top: 2px solid #5271ff;
}

.react-icons{
  color: #5271ff;
  font-size: 40px;
  margin-right: 70px;
}

.react-icons-null{
  color: grey;
  font-size: 40px;
  margin-right: 70px;
}

@media screen and (max-width: 600px) {
  .react-icons{
    margin-right: 55px;
  }

  .react-icons-null{
    margin-right: 55px
  }

  .footer-section-r{
    flex-wrap: wrap;
    margin-top: 40px;
  }

  .sections{
    display: block;
    width: 100%;
  }

  .footer{
    flex-direction: column;
  }

  .f-container{
    padding-left: 15px;
  }
}