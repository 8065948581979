.logo {
  height: 70px;
  margin-left: 20px;
}

.nav-links {
  display: flex;
  align-items: center;
}

.nav-items {
  list-style: none;
  display: flex;
}

.nav {
  background-color: white;
  position: absolute;
  width: 100%;
  min-height: 60px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0;
  box-shadow: 0 -6px 10px 5px rgba(0,0,0,0.5);
}

.nav-items > li {
  font-size: 20px;
  margin-right: 40px;
  cursor: pointer;
}

.nav-items > li > a {
    font-size: 20px;
    color: black;
    padding: 10px;
    font-weight: 500;
}

.nav-items > li > a:hover {
      color: #5271ff;
      font-weight: 500;
}

.contact-button {
  display: inline-block;
  background-color: #5271ff;
  padding: 15px 30px;
  color: white;
  text-align: center;
  border: 0;
  font-size: 20px;
  font-weight: 700;
  align-self: center;
  cursor: pointer;
  margin-right: 15px;
}

.contact-button:hover {
    background-color: #435ed4;
}


.video {
  align-self: center;
}

.header-icon {
  font-size: 35px;
  margin-right: 10px;
  position: absolute;
  top: 1rem;
  right: 1rem;
  display: none;
}

@media screen and (max-width: 1200px) {
  .nav-items li {
    font-size: 18px;
    margin-right: 30px;
  }

  .contact-button {
    width: 150px;
    font-size: 18px;
  }

  .nav-items {
    padding-left: 30px;
  }
}

@media screen and (max-width: 900px) {
  .header-icon {
    display: block;
    color: #5271ff;
    cursor: pointer;
  }

  .nav {
    flex-direction: column;
    align-items: flex-start;
  }

  .nav-links {
    width: 100%;
    flex-direction: column;
  }

  .nav-links :not(:last-child) {
      display: none;
   }

  .nav-links.expanded :not(:last-child) {
      display: block;
   }

  .nav-items {
    flex-direction: column;
    width: 100%;
    padding-left: 0px;
  }

   .nav-items li {
      width: 100%;
      font-size: 20px;
      text-align: center;
      padding-left: 0px;
      padding: 0px;
   }

   .nav-items li:hover {
        border-style: none;
        padding-left: 0px;
        background-color: #5271ff;
        border-radius: 0;
        padding: 0;
    }

    .nav-items li a {
        text-align: center;
        display: block;
        padding: 20px;
    }

    .nav-items >li >a:hover {
      color: white;
    }

  .contact-button {
    display: block;
    margin-bottom: 30px;
    width: calc(100% - 150px);
    border-radius: 10px;
    margin-right: 0px;
  }

  .logo {
    margin-left: 10px;
  }
}
