.product-specs{
  background-color: #DDE3FF;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  padding-top: 30px;
  padding-top: 30px;
}

.in-addition-img {
  width: 600px;
  max-height: 300px;
  margin-top: 20px;
  margin-bottom: 20px;
  margin-right: 10px;
  object-fit: scale-down;
}

.spec-table-container{

}

.spec-details{
  box-sizing: border-box;
  background-color: white;
  border-radius: 10px;
  padding: 15px;
  width: 100%;
  margin-bottom: 20px;
}

.spec-details h3, p{
  display: inline-block;
  margin-top: 10px;
}

.spec-block{
  align-items: center;
  display: flex;
  flex-direction: row;
  width: 100%;
}

.spec-block h3{
  flex: 1.2;
}

.spec-block p{
  flex: .8;
}

.buy-now{
  width: 175px;
  height: 50px;
  background-color: #5271ff;
  color: white;
  border: 0;
  border-radius: 25px;
  font-size: 18px;
  font-weight: 700;
  align-self: center;
  cursor: pointer;
}

.info-img {
  max-width: 100%;
  max-height: 600px;
}

.mac-button-container {
  display: flex;
  justify-content: center;
  margin-top: 25px;
}

.size-reference--list{
  width:100%;
}

.size-reference-list li {
  padding: 10px;
  font-size: 20px;
}

.size-reference-list p {
  font-size: 24px;
}

.size-reference-img{
  max-width: 400px;
  max-height: 500px;
  width: 400px;
  height: 500px;
  margin-right: 150px;
}

.size-reference-img-2 {
  max-width: 100%;
  max-height: 300px;
  width: 500px;
  height: 300px;
  margin-right: 100px;
}

.sr-image{
  width:500px;
  height:300px;
}

.sr-img-big {
  width: 400px;
  height: 500px;
}

.size-reference-container{
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 50px;
  margin-bottom: 50px;
}

.size-reference-heading{
  margin-left: 50px;
  font-size: 32px;
  margin-top: 50px;
}

.steps-grid{
  display: grid;
  grid-template-columns: auto auto auto;
  align-content: center;
  gap: 30px;
  margin: 10px 50px 10px 50px;
}

.steps-grid-item{
  text-align: center;
  border: 4px solid #5271ff;
  border-radius: 10px;
  padding-top: 30px;
}

.steps-grid-item h3{
  color: #5271ff;
  font-size: 32px;
  margin-top: 15px;
  margin-bottom: 10px;

}

.steps-grid-item p{
  font-size: 24px;
  margin-bottom: 30px;
}

.react-icons-machine{
  font-size: 80px;
  font-weight: bold;
}

.machine-info-container-blue{
  background-color: #5271ff;
  display: flex;
  align-items: center;
  padding-left: 50px;
  color: white;
}


.machine-info-container-blue h3, .machine-info-container-white h3{
  font-size: 40px;
}

.machine-info-container-blue p, .machine-info-container-white p{
  font-size: 20px;
}

.machine-info-container-white{
  background-color: white;
  display: flex;
  align-items: center;
  color: black;
}



.machine-info-container-white h3, .machine-info-container-white p{
  margin-left: 30px;
  margin-bottom: 20px;
}

.machine-info-container-blue h3, .machine-info-container-blue p{
  margin-right: 30px;
  margin-left: 30px;
  margin-bottom: 20px;
}

@media screen and (max-width: 1100px) {
  .product-specs{
    flex-direction: column;
  }

  .info-img{
    max-height: 500px;
  }

  .in-addition-img{
    width: 100%;
    height: 300px;
    max-height: 300px;
  }

  .size-reference-container {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-left: 0px;
  }

  .size-reference-img {
    max-width: 400px;
    display: flex;
    justify-content: center;
    width: 100%;
    height: 100%;
    margin-right: 0px;
  }

  .size-reference-img-2 {
    max-width: 100%;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    margin-right: 0px;
  }

  .size-reference-list {
    padding-left: 40px;
    padding-right: 40px;
    margin-top: 30px;
  }
}

@media screen and (max-width: 800px) {
  .steps-grid{
    grid-template-columns: auto auto;
  }
  
  .machine-info-container-blue{
    flex-direction: column-reverse;
    padding-left: 0;
  }

  .machine-info-container-white{
    flex-direction: column;
  }
  .machine-info-container-blue{
    padding-left: 0;
  }

  .in-addition-img{
    width: 100%;

    max-height: 300px;
  }

  .info-img-2{
    width: 400px;
  }

}

@media screen and (max-width: 600px) {
  .sr-img-big {
    width: 320px;
    height: 400px;
  }

  .in-addition-img{
    width: 100%;
    max-height: 300px;
  }

  .sr-image {
    width: 300px;
    height: 180px;
  }
  .machine-info-container-blue{
    padding-left: 0;
  }

  .spec-block{
    flex-direction: column;
    align-items: normal;
  }

  .size-reference-container{
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .product-specs{
    padding: 30px;
  }

  .info-img-2 {
    width: 300px;
  }

  .spec-table-container{
    width: 100%;
  }

  .steps-grid{
    grid-template-columns: auto;
  }
}