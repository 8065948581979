.listing-container{
  border-radius: 5px;
  border-style: solid;
  border-width: 2px;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 400px;
  padding-bottom: 50px;
  padding-top: 10px;
  justify-content: end;
}

.machine-list-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.product-grid{
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr;
  gap: 40px;
  margin-bottom: 50px;
  padding-left: 50px;
  padding-right: 50px;
}

.product-img {
  max-width: 250px;
  max-height: 300px;
}

.view-details {
  color: white;
  background-color: #5271ff;
  border-radius: 5px;
  border-style: none;
  width: 240px;
  height: 60px;
  font-size: 20px;
  font-weight: 700;
  align-self: center;
  cursor: pointer;
}

.product-desc{
  align-items: center;
  display: flex;
  flex-direction: column;
}

@media screen and (max-width: 1000px) {
  .product-grid{
    grid-template-columns: 1fr 1fr;
  }
}

@media screen and (max-width: 700px) {
  .product-grid{
    grid-template-columns: 1fr;
  }

  .machine-list-container {
    align-items: center;
  }
}