.homestay-banner {
  display: flex;
  padding-left: 50px;
  flex-direction: column;
  justify-content: flex-end;
  height: 650px;
  background-image: 
    linear-gradient(to right, rgba(255, 255, 255, 1), rgba(0, 0, 0, 0) 20%), 
    url(../assets/bb2301-artwork-02.jpg);
  background-size: cover;
  background-position: center;
  margin-bottom: 40px;
}

.homestay-headline{
  font-weight: 700;
  color: #5271ff;
  font-size: 48px;
  margin-bottom: 10px;
  margin-top: 100px;
}

.homestay-slogan{
  color: black;
  font-size: 24px;
  margin-top: 0;
  max-width: 500px;
  margin-bottom: 80px;
}

.homestay-main-content-1{
  text-align: center;
  margin-top: 40px;
  padding-left: 35px;
  padding-right: 35px;
  margin-bottom: 120px;
}

.homestay-main-content-1 h2{
  font-size: 40px;
}

.homestay-main-content-1 p{
  font-size: 24px;
}

.homestay-heading-2{
  text-align: center;
  font-size: 40px;
}

.homestay-main-content-2 {
  display: flex;
  flex-direction: row;
  background-color: #5271ff;
  background-image: linear-gradient(to top, #5271ff, #5271ff30 25%, rgba(0, 0, 0, 0) 75%);
  align-items: center;
  padding: 20px 20px 20px 20px;
}

  .homestay-main-content-2.first {
    background-color: transparent;
    padding-top: 40px;
    background-image: linear-gradient(to top, rgba(82,113,255,1) 0%, rgba(102,129,255,1) 70%, rgba(255,255,255,0) 95%);
  }

.main-content-2-wording{
  color: white;
  max-width: 900px;
  margin-left: 10px;
  margin-right: 10px;
}

.main-content-2-wording h2{
  font-size: 40px;
}

.main-content-2-wording p{
  font-size: 24px;
}

.homestay-image-machine{

  margin-left: 50px;
}

.homestay-image{
  max-height: 500px;
  max-width: 100%;
  width: 500px;
  margin: 20px;
}

@media screen and (max-width: 1000px) {
  .homestay-main-content-1 {
    margin-top: 0;
    margin-bottom: 60px;
  }

  .homestay-main-content-1 h2{
    font-size: 32px;
  }

  .homestay-main-content-1 p{
    font-size: 20px;
  }

  .homestay-main-content-2 h2{
    font-size: 32px;
  }

  .homestay-main-content-2 p{
    font-size: 20px;
  }

  .homestay-main-content-2.first{
    flex-direction: column-reverse;
  }

  .homestay-main-content-2.last{
    flex-direction: column-reverse;
  }

  .homestay-main-content-2{
    flex-direction: column;
  }

  .homestay-image{
    width: 100%;
    margin-left: 0px;
    margin-right: 0px;  
  }
}

@media screen and (max-width: 600px) {
  .homestay-main-content-1 h2{
    font-size: 30px;
  }

  .homestay-main-content-1 p{
    font-size: 18px;
  }

  .homestay-main-content-2{
    padding-left: 0px;
    padding-right: 0px;
  }

  .main-content-2-wording{
    padding-left: 15px;
    padding-right: 15px;
  }

  .homestay-image{
    margin-top: 0px;
  }

  .homestay-image-machine{
    max-height: 450px;
    margin-left: 0px;
  }

  .homestay-main-content-2 h2{
    font-size: 30px;
  }

  .homestay-main-content-2 p{
    font-size: 18px;
  }

  .homestay-main-content-2.first {
    padding-top: 20px;
  }
}