.contact-container{
  background-color: #5271ff;
  padding: 25px;
  padding-top: 60px;
}

.form-container{
  background-color: white;
  border-radius: 25px;
  padding: 50px
}

.contact-heading{
  color: white;
  text-align: center;
}

label{
  display: block;
  float: left;
  font-weight: 600;
}

input{
  display: block;
  border: 2px solid #5271ff;
  padding: 10px;
  box-sizing: border-box  ;
  margin-bottom: 15px;
  margin-top: 10px;
  font-size: 16px;
  width: 100%;
}

textarea{
  display: block;
  border: 2px solid #5271ff;
  resize: none;
  padding: 10px;
  box-sizing: border-box  ;
  margin-bottom: 15px;
  margin-top: 10px;
  font-size: 16px;
  width: 100%;
  font-family: 'Montserrat', sans-serif;
}

select{
  width: 100%;
  border: 2px solid #5271ff;
  padding: 10px;
  box-sizing: border-box  ;
  margin-bottom: 15px;
  margin-top: 10px;
  font-size: 16px;
}

button[type = "submit"]{
  width: 175px;
  height: 50px;
  background-color: #5271ff;
  color: white;
  border: 0;
  font-size: 20px;
  font-weight: 700;
  align-self: center;
  cursor: pointer;
  margin-top: 30px;
}

.form-content{
  display: flex;
  flex-direction: column;
}

.form-row{
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-right: 25px;
}

.direct-contact{
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.direct-contact{
  font-size: 30px;
}

.direct-contact-wording{
  display: flex;
  flex-direction: column;
  width: 500px;
  max-width: 100%;
  font-size: 20px;
}

.wording-icons{
  font-size: 22px;
  margin-right: 15px;
}

.maps-container {
  margin-right: 100px;
}

.google-maps-api{
  width: 500px;
  max-width: 100%;
  height: 100%;
  min-width: 250px;
}

.direct-contact-content{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%
}

.form-first-row{
  display: flex;
  justify-content: space-evenly;
}

/* Add Animation */
@-webkit-keyframes animatetop {
  from {
    top: -300px;
    opacity: 0
  }

  to {
    top: 0;
    opacity: 1
  }
}

@keyframes animatetop {
  from {
    top: -300px;
    opacity: 0
  }

  to {
    top: 0;
    opacity: 1
  }
}

.modal-header {
  padding: 2px 16px;
  background-color: #5271ff;
  color: white;
}

.modal-content {
  padding: 10px 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.modal {
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  padding-top: 100px; /* Location of the box */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0,0,0); /* Fallback color */
  background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
  border-style: none;
  border-width: 0;
}

.close-modal-button {
  width: 175px;
  height: 50px;
  background-color: #5271ff;
  color: white;
  border: 0;
  font-size: 20px;
  font-weight: 700;
  align-self: center;
  cursor: pointer;
  margin-top: 10px;
}

.modal-container {
  position: relative;
  background-color: #fefefe;
  margin: auto;
  padding: 0;
  border: 1px solid #888;
  width: 80%;
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2),0 6px 20px 0 rgba(0,0,0,0.19);
  -webkit-animation-name: animatetop;
  -webkit-animation-duration: 0.4s;
  animation-name: animatetop;
  animation-duration: 0.4s
}

.close-modal-button {
  display: block;
}

.contact-info-fields{
  display: flex;
  align-items: center;
  font-size: 20px;
  margin-bottom: 30px
}

.contact-info-fields p {
  margin: 0;  
}

@media screen and (max-width: 700px) {
  .form-first-row {
    flex-direction: column;
  }

  .form-row {
    margin-right: 0;
  }

  .direct-contact-content {
    flex-direction: column;
  }

  .maps-container{
    margin-right: 0;
    margin-bottom: 30px;
  }
}

@media screen and (max-width: 600px) {
  .form-first-row{
    flex-direction: column;
  }

  .form-row{
    margin-right: 0;
  }

  .direct-contact-content{
    flex-direction: column;
  }

  .form-container{
    padding-left:25px;
    padding-right:25px;
  }

  .contact-info-fields{
    font-size: 16px;
  }
}