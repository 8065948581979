.policy-heading{
  color: #5271ff;
  text-align: center;
  margin-bottom: 40px;
}

.policy-container{
  padding: 200px;
  padding-top: 120px;
  padding-bottom: 50px;
}

.policy-content h2{
  font-size: 26px;
}

.policy-content p{
  font-size: 18px;
}

.policy-content h3{
  font-size: 20px;
}

.policy-content ol li{
  font-size: 18px;
  padding: 10px;
}

.policy-content ol{
  list-style-type: lower-alpha;
}

.policy-content ul li{
  font-size: 18px;
  padding: 10px;
}

.policy-content hr{
  margin-top: 30px;
}

@media screen and (max-width: 750px) {
  .policy-container{
    padding: 40px;
    padding-top: 100px;
    padding-bottom: 50px;
  }
}