@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700&display=swap');

/* App wide css stylings */
body{
  margin: 0;
}

.headline{
  font-weight: 700;
  color: #5271ff;
  font-size: 48px;
  margin-bottom: 10px;
  margin-top: 100px;
}

.slogan{
  color: black;
  font-size: 24px;
  margin-top: 0;
  max-width: 600px;
  font-weight: 500;
}

/* Fonts */ 
body {
  font-family: 'Montserrat', sans-serif;
  box-sizing: border-box;
}

header {
  position: sticky; top: 0;
}


button{
  transition-duration: 0.3s;
}

a {
  text-decoration: none;
}

  @media screen and (max-width:700px) {
    .product-nav-img {
      max-height: 400px;
    }
  }

  h1, h2 {
    font-weight: bold;
  }