.hotel-banner{
  display: flex;
  padding-left: 50px;
  flex-direction: column;
  justify-content: flex-end;
  height: 650px;
  background-image:
  linear-gradient(to right, rgba(255, 255, 255, 1), rgba(0, 0,  0, 0) 20%),
  url(../assets/bb2301-artwork-01.jpg);
  background-size: cover;
  background-position: center;
}

.hotel-headline{
  font-weight: 700;
  color: #5271ff;
  font-size: 48px;
  margin-bottom: 10px;
  margin-top: 100px;
}

.hotel-slogan{
  color: black;
  font-size: 24px;
  margin-top: 0;
  max-width: 600px;
  margin-bottom: 80px;
}

.key-notes-1{
  display: flex;
  flex-direction: column;
  padding: 15px 25px 15px 25px;
  flex: 1.5;
}

.notes-1-heading{
  font-size: 36px;
}

.key-notes-1 p {
  font-size: 18px;
}

.key-notes-1 > ul > li{
  margin-bottom: 20px;
  font-weight: 600 ;
  font-size: 18px;
}

.key-notes-2{
  padding: 15px 25px 15px 25px;
  background-color: #5271ff;
  border-style: none;
  color: white;
  flex: 1;
}

.key-notes-2 p {
  font-size: 18px;
}

.hotel-points p {
  font-weight: 500;
  color: white;
}

.key-notes-2 h2 {
  font-size: 36px;
}

.notes-1-container{
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 30px;
  margin-bottom: 30px;
}

.notes-2-container{
  display: flex;
  flex-direction: row;
}

.notes-1-img{
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  padding-right: 10px;
}

.hotel-img-1{
  width: 500px;
  max-height: 100%;
  align-self: center;
}

.hotel-img-container{
  width: 100%;
  max-width: 600px;
}

.hotel-img-2{
  width: 100%;
  height: 100%;
}

.hotel-points{
  display: grid;
  grid-template-columns: auto auto;
  border-style: none;
}

.hotels-heading2{
  font-size: 36px;
  text-align: center;
}

@media screen and (max-width: 900px) {
  .notes-1-container{
    flex-direction: column-reverse;
  }

  .notes-2-container {
    flex-direction:column-reverse;
  }

  .hotel-img-container {
    max-width: 100%;
    min-height: 350px;
  }

  .notes-1-img{
    margin: 20px;
    padding-right: 0;
  }

  .hotel-img-1{
    width: 100%;
    height: 400px;
  }
}

@media screen and (max-width: 600px) {

  .key-notes-1 p, li {
    font-size: 16px;
  }

  .key-notes-1 h2 {
    font-size: 32px;
  }

  .key-notes-2 p, li {
    font-size: 16px;
  }

  .key-notes-2 h2 {
    font-size: 32px;
  }

  .hotel-img-1 {
    width: 100%;
    height: 100%
  }
}

@media screen and (max-width: 450px) {
  .hotel-img-container {
    max-width: 100%;
    height: 450px;
    min-height: 350px;
  }
}